import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Filters = {
	// searchQuery: string;
	// category: string;
	// priceRange: [number, number];
	startCursor: string | null
	endCursor: string | null
}

type FiltersState = {
	[page: string]: Filters
}

const defaultFilters: Filters = {
	// searchQuery: '',
	// category: '',
	// priceRange: [0, 100],
	startCursor: null,
	endCursor: null,
}

const initialState: FiltersState = {
	ordersPage: defaultFilters,
	itemsPage: defaultFilters,
}

export const filterSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		// setSearchQuery: (state, action: PayloadAction<{ page: string; query: string }>) => {
		//   state[action.payload.page].searchQuery = action.payload.query;
		// },
		// setCategory: (state, action: PayloadAction<{ page: string; category: string }>) => {
		//   state[action.payload.page].category = action.payload.category;
		// },
		// setPriceRange: (state, action: PayloadAction<{ page: string; range: [number, number] }>) => {
		//   state[action.payload.page].priceRange = action.payload.range;
		// },
		setCursors: (
			state,
			action: PayloadAction<{
				page: string
				startCursor: string | null
				endCursor: string | null
			}>,
		) => {
			state[action.payload.page].startCursor = action.payload.startCursor
			state[action.payload.page].endCursor = action.payload.endCursor
		},
		resetFilters: (state, action: PayloadAction<string>) => {
			state[action.payload] = defaultFilters
		},
	},
})

export const { setCursors, resetFilters } = filterSlice.actions

export default filterSlice.reducer
